import {
  makeStyles,
  createStyles,
  Typography,
  Button,
  Box,
  Container
} from '@material-ui/core'
import React from 'react'
import SEO from '../components/seo'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      height: 'calc(100vh - 84px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    link: {
      textDecoration: 'none'
    },
    actionButton: {
      textTransform: 'none',
      borderRadius: 26,
      fontWeight: 400,
      fontSize: 16,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20
      }
    }
  })
)

const WhitepaperPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Whitepaper" />
      <Container className={classes.wrapper}>
        <Typography component="h2" variant="h3">
          Token Model
        </Typography>
        <Box my={2}>
          <Typography>
            Click the button below to download the Token Model.
          </Typography>
        </Box>
        <a
          href="../../Halo Token Model.pdf"
          target="_blank"
          className={classes.link}
        >
          <Button
            variant="outlined"
            className={classes.actionButton}
            color="primary"
          >
            Download Token Model
          </Button>
        </a>
      </Container>
    </Layout>
  )
}

export default WhitepaperPage
